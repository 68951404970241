.org-chart-container {
  width: 100%;
  padding: 10px;
  background: #0a0a0a; /* Dark background */
  color: #fff; /* Light text */
  font-family: 'Orbitron', sans-serif;
}

.search-form {
  margin-bottom: 20px;
}

.search-form input[type="text"], 
.search-form input[type="number"] {
  margin-right: 5px;
  background: #222; /* Dark input fields */
  border: 1px solid #3a3a3a;
  color: #ff8c00; /* Orange text color */
}

.search-results {
  margin-top: 20px;
  margin-bottom: 20px;
}

.employee-node, .department-node, .organization-node {
  margin: 5px;
  padding: 10px;
  border: 1px solid #3a3a3a;
  border-radius: 5px;
  cursor: pointer;
  background: #121212; /* Dark node background */
  box-shadow: 0 0 10px 0 rgba(255, 140, 0, 0.6); /* Orange glow effect */
}

.department-row {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.employee-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.node-details {
  margin-top: 20px;
  border-top: 1px solid #3a3a3a;
  padding-top: 10px;
}

/* Hover effects for interactive elements */
.employee-node:hover, .department-node:hover, .organization-node:hover {
  border-color: #ff8c00; /* Orange border on hover */
  color: #ff8c00;
}

/* Additional styling as needed */
