.App-header {
  background-color: #000; /* Dark background for contrast */
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ff6a00; /* Vibrant orange for text */
}

/* Additional styling can include hover effects, borders, and more. */
/* Example: */

.App-header:hover {
  border: 2px solid #ff6a00; /* Adding an orange border on hover */
}

/* You might also want to style other elements in your app with orange accents */
.other-element {
  color: #ff6a00; /* Orange text */
  border: 1px solid #ff6a00; /* Orange border */
}

/* For buttons or interactive elements */
.button {
  background-color: #ff6a00; /* Orange background */
  color: #282c34; /* Dark text for contrast */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e65c00; /* Darker shade of orange on hover */
}
